import BaseInput from '@/components/base/baseInput';
import FeaturesAutocomplete from '@/components/features/featuresAutocomplete';
import { bankInfoType } from '@/pages/mine/addPaymentMethod/useStateHooks';
import { OperatingEnum } from '@/pages/mine/bank/useStateHooks';
import { getBankListApi } from '@/services/api/requestApi';
import { toJsonData } from '@/utils/socket';
import { Button } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * 银行信息录入组件
 *
 * @param {IProps} props 组件接收的props
 * @param {bankInfoType} bankInfo 当前的银行信息对象，包含银行相关字段
 * @param {Dispatch<SetStateAction<bankInfoType>>} setBankInfo 设置银行信息的函数
 * @param {(type: OperatingEnum) => Promise<void>} submitBankInfo 提交银行信息的函数，异步操作
 * @param {OperatingEnum} operatingType 操作类型，用于区分不同的操作场景
 * @returns 返回JSX元素，用于渲染银行信息录入界面
 */
export default ({
  bankInfo,
  setBankInfo,
  submitBankInfo,
  operatingType,
}: IProps) => {
  const { t } = useTranslation(); // 使用i18n库获取翻译函数
  // 银行信息录入界面的JSX结
  const { isMobile } = useModel('system');
  // 动态表单
  const { appInfo } = useModel('appInfo');
  const dynamicForm = toJsonData(appInfo?.BANK_CARD_INFO_CONFIG);
  return (
    <div className='flex flex-col gap-3'>
      <div className='flex flex-col gap-2'>
        <div className="flex items-center   text-foreground">
          <span className="text-errorColor mr-1">*</span>
          {t('银行名称')}
        </div>
        <FeaturesAutocomplete
          // 银行名称自动补全组件
          className="!bg-background rounded-md"
          variant="bordered"
          inputValue={bankInfo?.bankName}
          itemValue="name"
          allowsCustomValue
          onInputChange={(value) => {
            setBankInfo({
              ...bankInfo,
              bankName: value,
            });
          }}
          api={getBankListApi}
          placeholder={t('银行')}
        />
      </div>

      <div className='flex flex-col gap-2'>
        <div className="flex  items-center  text-foreground">
          <span className="text-errorColor mr-1">*</span>
          {t('卡号')}
        </div>
        <BaseInput
          // 银行卡号输入框
          placeholder={t('卡号')}
          value={bankInfo?.cardNumber}
          onChange={(e) =>
            setBankInfo({
              ...bankInfo,
              cardNumber: e.target.value,
            })
          }
        />
      </div>

      <div className='flex flex-col gap-2'>
        <div className="flex items-center  text-foreground">{t('持卡人')}</div>
        <BaseInput
          // 持卡人姓名输入框
          placeholder={t('持卡人')}
          value={bankInfo?.cardHolder}
          onChange={(e) =>
            setBankInfo({
              ...bankInfo,
              cardHolder: e.target.value,
            })
          }
        />
      </div>

      {dynamicForm?.map((item: any, i: any) => {
        return (
          <div key={item.field} className='flex flex-col gap-2'>
            <div className="flex items-center  text-foreground">
              {item?.required === 'true' && (
                <span className="text-errorColor mr-1">*</span>
              )}
              {t(item.label)}
            </div>
            <BaseInput
              // 开户行名称输入框
              placeholder={t(item.label)}
              value={bankInfo?.['info']?.[i]?.value}
              onChange={(e) =>
                setBankInfo((prevState: any) => {
                  const info = [...prevState.info];
                  info[i].value = e.target.value;
                  return {
                    ...prevState,
                    info,
                  };
                })
              }
            />
          </div>
        );
      })}

      <div
        className={` ${isMobile ? 'fixed' : 'relative'} 
          ${isMobile ? 'px-4' : ''}
          ${isMobile ? '' : 'pt-16'}
          w-full   bottom-4 left-0 `}
      >
        <Button
          // 提交按钮
          className="mainColorButton  mt-2 mb-6"
          onClick={() => {
            submitBankInfo(operatingType);
          }}
        >
          {t('提交')}
        </Button>
      </div>
    </div>
  );
};

// 组件所需的props接口定义
interface IProps {
  bankInfo: bankInfoType;
  setBankInfo: Dispatch<SetStateAction<bankInfoType>>;
  submitBankInfo: (type: OperatingEnum) => any;
  operatingType: OperatingEnum;
}
