import {
  getPersonBankListApi,
  getPersonVirtualListApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import useUrlState from '@ahooksjs/use-url-state';
import { useMount } from 'ahooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// 定义类型枚举
export enum TypeEnum {
  BANK = 'bank', // 银行卡
  VIRTUAL_CURRENCY = 'virtualCurrency', // 虚拟货币
}

// 定义操作枚举
export enum OperatingEnum {
  ADD = 'add', // 添加
  EDIT = 'edit', // 编辑
}

/**
 * 使用状态钩子，管理银行卡和虚拟货币的选项、操作类型、以及相关数据的获取和提交。
 *
 * @returns 返回一个包含选项、类型、操作、信息设置和获取请求等方法和数据的对象。
 */
export default function useStateHooks() {
  /*---------------------------模块整体数据------------------------------*/
  const { t } = useTranslation();
  const [urlState] = useUrlState<any>({});
  const { type: defaultType } = urlState;

  // 初始化选项卡数据
  let tabs = [
    // {
    //   value: TypeEnum.BANK,
    //   text: t('银行卡'),
    // },
    {
      value: TypeEnum.VIRTUAL_CURRENCY,
      text: t('虚拟钱包'),
    },
  ];
  // 使用useState管理当前选择的类型，默认为银行卡
  const [type, setType] = useState<TypeEnum>(
    defaultType ?? TypeEnum.VIRTUAL_CURRENCY,
  );

  // 更改当前选择的类型
  const changeType = (type: TypeEnum) => {
    setType(type);
  };

  /*---------------------------银行卡tab下数据------------------------------*/
  // 使用自定义钩子请求当前用户下的所有可用银行卡列表
  const {
    data: bankList = [] as any,
    run: getBankList,
    loading,
  } = useReq(getPersonBankListApi, {
    loadingDefault:false,
    defaultValue: [],
    manual:true
  });

  /*---------------------------虚拟币tab下数据------------------------------*/
  // 使用自定义钩子请求当前用户下的所有可用银行卡列表
  const {
    data: virtualList = [] as any,
    run: getVirtualList,
    loading: virtualListLoading,
  } = useReq(getPersonVirtualListApi, {
    loadingDefault:false,
    manual: true,
    defaultValue: [],
  });

  // 在组件挂载时触发获取虚拟卡列表
  useMount(() => {
    getVirtualList();
  });

  //搜索文字
  const [searchText, setSearchText] = useState<string>('');

  // 返回所有管理状态和操作的属性和方法
  return {
    tabs, // 选项
    type, // 类型
    changeType, // 更改类型
    bankList, // 银行卡列表
    virtualList, // 虚拟币列表
    searchText,
    setSearchText,
    loading,
    virtualListLoading,
  };
}
