import { Fa6SolidShop } from '@/assets/icons/mine/Fa6SolidShop';
import { SolarWalletBroken } from '@/assets/icons/mine/SolarWalletBroken';
import BaseInput from '@/components/base/baseInput';
import FeaturesAutocomplete from '@/components/features/featuresAutocomplete';
import { virtualInfoType } from '@/pages/mine/addPaymentMethod/useStateHooks';
import { OperatingEnum } from '@/pages/mine/bank/useStateHooks';
import { getVirtualListApi } from '@/services/api/requestApi';
import { Button } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * 负责渲染虚拟信息输入界面，并处理相关的用户输入操作。
 *
 * @param virtualInfo 当前的虚拟信息对象，包含网络ID、地址URL和备注名等。
 * @param setVirtualInfo 用于更新虚拟信息对象的函数。
 * @param submitVirtualInfo 提交虚拟信息的函数，会根据操作类型执行相应的提交逻辑。
 * @param operatingType 当前操作的类型，决定了提交虚拟信息时的行为。
 * @returns 返回一个JSX元素，包含区块链地址、加密钱包地址、备注名的输入框以及提交按钮。
 */
export default ({
                  virtualInfo,
                  setVirtualInfo,
                  submitVirtualInfo,
                  operatingType,
                }: IProps) => {
  const { t } = useTranslation(); // 使用i18n库获取当前语言环境的文本翻译函数

  const { isMobile } = useModel('system');
  // 渲染界面
  return (
    <div className='flex flex-col gap-3'>
      <div className='flex flex-col gap-2'>
        <div className="flex items-center  text-foreground">
          {t('网络')}
        </div>
        <FeaturesAutocomplete
          className="!bg-background rounded-md  text-foreground"
          variant="bordered"
          selectedKey={
            virtualInfo?.networkId ? virtualInfo.networkId + '' : undefined
          }
          icon={'iconUrl'}
          itemText="name"
          itemValue="id"
          onSelectionChange={(value: any) => {
            setVirtualInfo({
              ...virtualInfo,
              networkId: value,
            });
          }}
          startContent={<Fa6SolidShop className="text-auxiliaryTextColor" />}
          api={getVirtualListApi}
          placeholder={t('网络')}
        />
      </div>
      <div className='flex flex-col gap-2'>
        <div className="flex items-center   text-foreground">
          {t('加密钱包地址')}
        </div>
        <BaseInput
          placeholder={t('加密钱包地址')}
          value={virtualInfo?.addressUrl}
          onChange={(e) =>
            setVirtualInfo({
              ...virtualInfo,
              addressUrl: e.target.value,
            })
          }
          startContent={
            <SolarWalletBroken className="text-auxiliaryTextColor" />
          }
        />
      </div>
      <div className='flex flex-col gap-2'>
        <div className="  flex items-center  text-foreground">
          {t('钱包名称')}
        </div>
        <BaseInput
          placeholder={t('钱包名称')}
          value={virtualInfo?.remark}
          onChange={(e) =>
            setVirtualInfo({
              ...virtualInfo,
              remark: e.target.value,
            })
          }
          startContent={
            <SolarWalletBroken className="text-auxiliaryTextColor" />
          }
        />
      </div>
      <div
        className={` ${isMobile ? 'fixed' : 'relative'} 
          ${isMobile ? 'px-4' : ''}
          ${isMobile ? '' : 'pt-16'}
          w-full   bottom-4 left-0 `}
      >
        <Button
          className="mainColorButton  mt-2 mb-6"
          onClick={() => {
            submitVirtualInfo(operatingType);
          }}
        >
          {t('提交')}
        </Button>
      </div>
    </div>
  );
};

interface IProps {
  virtualInfo: virtualInfoType; // 虚拟信息的类型
  setVirtualInfo: Dispatch<SetStateAction<virtualInfoType>>; // 更新虚拟信息的函数
  submitVirtualInfo: (type: OperatingEnum) => void; // 提交虚拟信息的函数
  operatingType: OperatingEnum; // 当前操作类型
}
