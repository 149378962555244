import {useReq} from '@/services/net/request';
import {Autocomplete, AutocompleteItem, AutocompleteProps,} from '@nextui-org/react';
import {useMount} from 'ahooks';
import {useTranslation} from 'react-i18next';

/**
 * FeaturesAutocomplete 是一个具有自动补全功能的输入组件。
 * 它利用了外部提供的 api 来获取补全选项，并根据用户的输入动态展示这些选项。
 *
 * @param {IProps} props 组件接收的属性对象，包含了自动补全所需的配置和状态。
 * @param {string} itemText 用于展示在自动补全列表中的文本字段，默认为 'name'。
 * @param {string} itemValue 用于标识选中项的唯一值的字段，默认为 'id'。
 * @param {any} api 用于获取自动补全数据的 API 函数。
 * @param {any} params 传递给 api 函数的参数。
 * @returns 返回一个具有自动补全功能的输入组件。
 */
export default function FeaturesAutocomplete({
                                               itemText = 'name',
                                               itemValue = 'id',
                                               api,
                                               params,
                                               icon,
                                                 isI18=false,
                                               ...props
                                             }: IProps) {
  // 使用 useReq 钩子来处理 api 请求，自动处理请求状态和数据。
  const {
    data = [] as any,
    run,
  } = useReq(api, {
    manual: true,
    defaultValue: [],
  });

  // 组件挂载时触发 api 请求，以获取初始数据。
  useMount(() => {
    run(params);
  });

  // 使用 i18n 钩子来支持多语言。
  const { t } = useTranslation();
  // 渲染自动补全组件，配置基本样式和行为。
  return (
    <>
      {
        <Autocomplete
          classNames={{
            listboxWrapper: 'rounded-md',
          }}
          {...props}
          inputProps={{
            classNames: {
              input:
                'bg-[transparent] rounded-md caret-darkBrandColor text-backContrastColor',
              inputWrapper:
                'rounded-md border-1 group-data-[focus=true]:border-primary',
            },
          }}
          listboxProps={{
            className: 'bg-background',
          }}
          defaultItems={data as any[]}
        >
          {data?.map((item: any) => {
            // 为每个补全选项渲染自定义的项组件，展示对应的文本。
            return (
              <AutocompleteItem
                className="text-foreground"
                key={item[itemValue]}
                startContent={<>
                  {icon && <img src={item[icon]} className='w-6 h-6 rounded-full' alt="" />}
                </>}
              >
                {isI18 ?  t(item[itemText]) : item[itemText]}
              </AutocompleteItem>
            );
          })}
        </Autocomplete>
      }
    </>
  );
}

// 定义组件接收的 props 的类型定义。
interface IProps extends Partial<AutocompleteProps> {
  itemValue?: string;
  itemText?: string;
  params?: any;
  api: any;
  icon?: any;
  isI18?:boolean
}
