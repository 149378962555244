import PageEnum from '@/enums/pageEnum';
import { OperatingEnum, TypeEnum } from '@/pages/mine/bank/useStateHooks';
import {
  addBankApi,
  addVirtualApi,
  editBankApi,
  editVirtualApi,
  getBankCardInformationApi,
  getIndividualVirtualCardInformationApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { toJsonData } from '@/utils/socket';
import { useModel } from '@@/exports';
import useUrlState from '@ahooksjs/use-url-state';
import { history } from '@umijs/max';
import { useMount, useUpdateEffect } from 'ahooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';

export default function useStateHooks() {
  //获取状态
  const [urlState] = useUrlState<any>({});
  const { t } = useTranslation();
  const { type, operatingType, id } = urlState || {};
  const getTitle = () => {
    if (type === TypeEnum.BANK) {
      if (operatingType === OperatingEnum.ADD) {
        return t('添加银行卡');
      } else {
        return t('修改银行卡');
      }
    } else {
      if (operatingType === OperatingEnum.ADD) {
        return t('添加虚拟币');
      } else {
        return t('修改虚拟币');
      }
    }
  };
  /*-------------------------------- 银行卡相关------------------------------- */
  const { appInfo } = useModel('appInfo');
  const dynamicForm = toJsonData(appInfo?.BANK_CARD_INFO_CONFIG);
  const [bankInfo, setBankInfo] = useState<any>({
    id: -1,
    cardNumber: '',
    bankName: '',
    cardHolder: '',
    info: toJsonData(appInfo?.BANK_CARD_INFO_CONFIG),
  });
  const { run: getBankCardInformationReq, data: bankInfoData } = useReq(
    getBankCardInformationApi,
    {
      manual: true,
      onSuccess(data: any) {
        setBankInfo({ ...data, info: toJsonData(data?.info) });
      },
    },
  );
  useMount(() => {
    if (type === TypeEnum.BANK && operatingType === OperatingEnum.EDIT) {
      getBankCardInformationReq({ id });
    }
  });
  // 分别使用useReq钩子管理添加和编辑银行卡的请求，设置为手动触发
  const { run: addBank } = useReq(addBankApi, {
    manual: true,
    onSuccess() {
      toast.success(t('提交成功'));
      history.replace(PageEnum.BANK);
    },
  });
  const { run: editBank } = useReq(editBankApi, {
    manual: true,
    onSuccess() {
      toast.success(t('修改成功'));
      history.replace(PageEnum.BANK);
    },
  });
  // 提交银行卡信息，根据操作类型调用添加或编辑接口，并在完成后刷新列表并关闭对话框
  const submitBankInfo = (type: OperatingEnum) => {
    if (!bankInfo?.bankName.trim()) return toast.error(t('请填写银行名称'));
    if (!bankInfo?.cardNumber.trim()) return toast.error(t('请填写银行卡号'));
    for (let i = 0; i < bankInfo['info']?.length; i++) {
      if (
        bankInfo['info'][i].required === 'true' &&
        !bankInfo['info'][i]?.value?.trim()
      ) {
        return toast.error(t('请填写') + dynamicForm[i]?.label);
      }
    }
    if (type === OperatingEnum.ADD) {
      addBank({ ...bankInfo, info: JSON.stringify(bankInfo['info']) });
    } else {
      editBank({ ...bankInfo, info: JSON.stringify(bankInfo['info']) });
    }
  };
  /*-------------------------------- 虚拟卡相关------------------------------- */
  const [virtualInfo, setVirtualInfo] = useState({
    id: -1,
    addressUrl: '',
    remark: '',
    networkId: '',
    agentAvatar: '',
    memberPhone: '',
  });
  const {
    run: getIndividualVirtualCardInformationReq,
    data: individualVirtualCardInformation,
  } = useReq(getIndividualVirtualCardInformationApi, {
    manual: true,
  });
  useMount(() => {
    if (
      type === TypeEnum.VIRTUAL_CURRENCY &&
      operatingType === OperatingEnum.EDIT
    ) {
      getIndividualVirtualCardInformationReq({ id });
    }
  });
  useUpdateEffect(() => {
    setVirtualInfo(individualVirtualCardInformation as any);
  }, [individualVirtualCardInformation]);
  // 分别使用useReq钩子管理添加和编辑银行卡的请求，设置为手动触发
  const { run: addVirtual } = useReq(addVirtualApi, {
    manual: true,
    onSuccess() {
      toast.success(t('提交成功'));
      history.replace(PageEnum.BANK);
    },
  });
  const { run: editVirtual } = useReq(editVirtualApi, {
    manual: true,
    onSuccess() {
      toast.success(t('修改成功'));
      history.replace(PageEnum.BANK);
    },
  });

  // 提交虚拟卡信息，根据操作类型调用添加或编辑接口，并在完成后刷新列表并关闭对话框
  const submitVirtualInfo = (type: OperatingEnum) => {
    if (!virtualInfo?.networkId.trim()) return toast.error(t('区块链地址未选择！'));
    if (!virtualInfo?.addressUrl.trim()) return toast.error(t('钱包地址未填写！'));
    if (!virtualInfo?.remark.trim()) return toast.error(t('钱包名称未填写！'));
    if (type === OperatingEnum.ADD) {
      addVirtual(virtualInfo);
    } else {
      editVirtual(virtualInfo);
    }
  };

  return {
    bankInfo, // 银行卡信息
    setBankInfo, // 设置银行卡信息
    submitBankInfo, // 提交银行卡信息
    virtualInfo, // 虚拟卡信息
    setVirtualInfo, // 设置虚拟卡信息
    submitVirtualInfo, // 提交虚拟卡信息
    getTitle, // 获取标题
    type, // 类型
    operatingType, // 操作类型
  };
}

// 定义hooksType类型，为useStateHooks函数的返回类型
type hooksType = ReturnType<typeof useStateHooks>;
// 定义bankInfoType类型，为useStateHooks函数返回对象中的bankInfo属性类型
export type bankInfoType = hooksType['bankInfo'];
// 定义virtualInfoType类型，为useStateHooks函数返回对象中的virtualInfo属性类型
export type virtualInfoType = hooksType['virtualInfo'];
