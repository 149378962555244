import { CarbonCloseOutline } from '@/assets/icons/comm/CarbonCloseOutline';
import type { InputProps } from '@nextui-org/react';
import { Button, Input } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useRef } from 'react';

/**
 * 基本的输入框
 * @returns
 */
export default function BaseInput(
  props: InputProps & { isShowClear?: boolean },
) {
  const {
    value,
    onChange,
    endContent,
    isShowClear = true,
    classNames,
    className,
    onFocus,
    onBlur,
  } = props;
  const { setIsHideBottomNav } = useModel('compontentSetting');
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Input
      ref={inputRef}
      variant="bordered"
      isClearable
      {...props}
      autocomplete="off"
      onFocus={(event) => {
        setIsHideBottomNav(true);
        onFocus?.(event);
      }}
      onBlur={(event) => {
        setIsHideBottomNav(false);
        onBlur?.(event);
      }}
      classNames={{
        ...props?.classNames,
        input: `bg-[transparent] rounded-md caret-darkBrandColor text-backContrastColor  ${classNames?.input} custom-input`,
        inputWrapper: `rounded-md relative border-1 group-data-[focus=true]:border-primary ${
          isShowClear ? 'pr-[40px]' : ''
        } ${classNames?.inputWrapper}`,
        clearButton: `h-full ${classNames?.clearButton}`,
      }}
      className={`min-h-[20px] ${className}`}
      endContent={
        <div
          className="text-sm outline-none flex items-center h-full"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            return false;
          }}
          tabIndex={-1} // 禁止聚焦
        >
          {!!(value && isShowClear) && (
            <Button
              tabIndex={-1} // 禁止聚焦
              className="!mr-[-12px] text-xl bg-transparent"
              onClick={() => {
                onChange?.({
                  target: {
                    value: '',
                  },
                } as any);
              }}
              isIconOnly
            >
              <CarbonCloseOutline tabIndex={-1} className="text-foreground" />
            </Button>
          )}
          {endContent}
        </div>
      }
    />
  );
}
