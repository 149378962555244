import React from 'react';
import type {SVGProps} from 'react';

export function Fa6SolidShop(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
        <g fill="none">
            <path stroke="currentColor" strokeWidth={1.5} d="M9.5 2h5l.652 6.517a3.168 3.168 0 1 1-6.304 0z"></path>
            <path stroke="currentColor" strokeWidth={1.5}
                  d="M3.33 5.351c.178-.89.267-1.335.448-1.696a3 3 0 0 1 1.888-1.548C6.056 2 6.51 2 7.418 2H9.5l-.725 7.245a3.06 3.06 0 1 1-6.043-.904zm17.34 0c-.178-.89-.267-1.335-.448-1.696a3 3 0 0 0-1.888-1.548C17.944 2 17.49 2 16.582 2H14.5l.725 7.245a3.06 3.06 0 1 0 6.043-.904z"></path>
            <path fill="currentColor"
                  d="M8.75 21.5a.75.75 0 0 0 1.5 0zm5 0a.75.75 0 0 0 1.5 0zm0-5.299l.375-.65zm.549.549l-.65.375zm-4.598 0l.65.375zm.549-.549l.375.65zm2.25 5.049h-1v1.5h1zM4.25 14v-3h-1.5v3zm15.5-3v3h1.5v-3zM11.5 21.25c-1.907 0-3.261-.002-4.29-.14c-1.005-.135-1.585-.389-2.008-.812l-1.06 1.06c.748.75 1.697 1.081 2.869 1.239c1.15.155 2.625.153 4.489.153zM2.75 14c0 1.864-.002 3.338.153 4.489c.158 1.172.49 2.121 1.238 2.87l1.06-1.06c-.422-.424-.676-1.004-.811-2.01c-.138-1.027-.14-2.382-.14-4.289zm9.75 8.75c1.864 0 3.338.002 4.489-.153c1.172-.158 2.121-.49 2.87-1.238l-1.06-1.06c-.424.422-1.004.676-2.01.811c-1.028.138-2.382.14-4.289.14zM19.75 14c0 1.907-.002 3.262-.14 4.29c-.135 1.005-.389 1.585-.812 2.008l1.06 1.06c.75-.748 1.081-1.697 1.239-2.869c.155-1.15.153-2.625.153-4.489zm-9.5 7.5v-3h-1.5v3zm3.5-3v3h1.5v-3zM12 16.75c.481 0 .792 0 1.027.022c.225.02.307.055.348.079l.75-1.3c-.307-.177-.633-.243-.962-.273c-.32-.029-.71-.028-1.163-.028zm3.25 1.75c0-.453 0-.844-.028-1.163c-.03-.329-.096-.655-.273-.962l-1.3.75c.024.04.059.123.079.348c.021.235.022.546.022 1.027zm-1.875-1.65a.75.75 0 0 1 .274.275l1.3-.75a2.251 2.251 0 0 0-.824-.824zM10.25 18.5c0-.481 0-.792.022-1.027c.02-.225.055-.307.079-.348l-1.3-.75c-.177.307-.243.633-.273.962c-.029.32-.028.71-.028 1.163zM12 15.25c-.453 0-.844 0-1.163.028c-.329.03-.655.096-.962.273l.75 1.3c.04-.024.123-.058.348-.079c.235-.021.546-.022 1.027-.022zm-1.65 1.875a.75.75 0 0 1 .275-.274l-.75-1.3a2.25 2.25 0 0 0-.824.824z"
                  opacity={0.5}></path>
        </g>
    </svg>);
}