import BaseTopNav from '@/components/base/baseTopNav';
import BankForm from '@/pages/mine/addPaymentMethod/components/BankForm';
import BankVirtualCurrencyForm from '@/pages/mine/addPaymentMethod/components/BankVirtualCurrencyForm';
import useStateHooks from '@/pages/mine/addPaymentMethod/useStateHooks';
import { TypeEnum } from '@/pages/mine/bank/useStateHooks';
import useUrlState from '@ahooksjs/use-url-state';

/**
 *添加银行卡页面
 */

export default () => {
  //获取状态
  const {
    bankInfo, // 银行卡信息
    setBankInfo, // 设置银行卡信息
    submitBankInfo, // 提交银行卡信息
    virtualInfo, // 虚拟卡信息
    setVirtualInfo, // 设置虚拟卡信息
    submitVirtualInfo, // 提交虚拟卡信息
    getTitle, // 获取标题
    type, // 类型
    operatingType, // 操作类型
  } = useStateHooks();
  const [urlState] = useUrlState<any>({});
  const {targetUrl,...resetUrlState} = urlState

  return (
    <>
      <div className="pb-4">
        <BaseTopNav title={getTitle()}></BaseTopNav>
        <div className="px-4 mt-4">
          {type === TypeEnum.BANK && (
            <BankForm
              bankInfo={bankInfo}
              setBankInfo={setBankInfo}
              submitBankInfo={submitBankInfo}
              operatingType={operatingType}
            />
          )}
          {type === TypeEnum.VIRTUAL_CURRENCY && (
            <BankVirtualCurrencyForm
              virtualInfo={virtualInfo}
              setVirtualInfo={setVirtualInfo}
              submitVirtualInfo={submitVirtualInfo}
              operatingType={operatingType}
            />
          )}
        </div>
      </div>
    </>
  );
};
