import { MaterialSymbolsArrowBack } from '@/assets/icons/layout/MaterialSymbolsArrowBack';
import { history } from '@umijs/max';
import { useMount } from 'ahooks';
import { ReactNode, useState } from 'react';

/**
 * 顶部的导航
 */
type iprops = {
  leftNode?: ReactNode | string;
  title?: ReactNode | string;
  rightNode?: any;
  onBack?: () => void;
  gridTemplateColumns?: string;
};

export default function BaseTopNav(props: iprops) {
  const [isClickable, setIsClickable] = useState(false);

  // 在组件挂载后1秒钟，设置为可点击
  useMount(() => {
    setTimeout(() => {
      setIsClickable(true);
    }, 300); // 500 ms秒后设置为可点击
  });

  const { leftNode, title = '', rightNode = '', onBack,gridTemplateColumns } = props;

  const handleBackClick = () => {
    if (!isClickable) {
      // 如果在1秒钟内点击，不执行任何操作
      return;
    }

    if (onBack) {
      onBack();
    } else {
      history.back();
    }
  };

  return (
    <div className="text-xl">
      <div
        className="items-center justify-between"
        style={{ display: 'grid', gridTemplateColumns: gridTemplateColumns ?? '1fr auto 1fr' }}
      >
        <div className="w-full flex justify-start">
          {leftNode ?? (
            <div className="p-4 select-none" onClick={handleBackClick}>
              <MaterialSymbolsArrowBack className="text-xl font-bold" />
            </div>
          )}
        </div>

        <div className="w-full text-center flex justify-center font-bold text-base">
          {title}
        </div>
        <div className="w-full flex justify-end h-full items-center pl-1">
          {rightNode}
        </div>
      </div>
    </div>
  );
}
